<template>
  <div :class="classes">
    <section class="user-forms-login__google flow">
      <div id="google-connect"></div>
    </section>

    <div class="user-forms-login__separator">
      <p class="user-forms-login__or">{{ $t('user-portal.or') }}</p>
    </div>

    <!-- Login -->
    <form
      :class="classes"
      @submit="onSubmit"
      v-if="!loading">

      <!-- Error banner -->
      <ui-banner
        :description="errorsLogin"
        intent="danger"
        v-if="hasErrors"
      />

      <!-- Email -->
      <forms-input
        autofocus
        :errors="getErrors('email')"
        name="email"
        :placeholder="$t('user-portal.user_email_placeholder')"
        ref="email"
        required
        type="email"
        @input="onRemoveError('email')"
        v-model="user.email"
      >{{ $t('user-portal.user_email') }}</forms-input>

      <!-- Password -->
      <forms-input
        :errors="getErrors('password')"
        name="password"
        :placeholder="$t('user-portal.user_password_placeholder')"
        ref="password"
        required
        type="password"
        @input="onRemoveError('password')"
        v-model="user.password"
      >{{ $t('user-portal.user_password') }}</forms-input>
    </form>

    <!-- Forgot password -->
    <actions-button
      appearance="subtle"
      class="user-forms-login__link"
      type="submit"
      @click.stop.prevent="$emit('lost-password')"
    >{{ $t('user-portal.user_lost_password_action')}}</actions-button>
  </div>
</template>

<script>
import UiBanner from '@/components/ui/banner'
import MixinError from '@/helpers/errors'

import { initGoogleLogin } from '@/helpers/google-login.js'

import SaylStore from '@/bootstrap/stores'

export default {
  name: 'FormsLogin',

  inject: [
    '$embed',
    '$redirect',
    '$user',
  ],

  mixins: [MixinError],

  model: {
    prop: 'user',
    event: 'change',
  },

  props: {
    errors: {
      type: Object | Array,
    },
    
    intent: {},

    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    UiBanner
  },

  data() {
    return {
      passwordFieldType: 'password',

      bootstrap: SaylStore.bootstrap(),
    }
  },

  computed: {
    classes() {
      return {
        'user-forms-login': true,
        'flow': true,
      }
    },

    errorsLogin() {
      if(this.getErrors('redirect').length > 0) {
        return this.getErrors('redirect').join(', ')
      }

      if(this.getErrors('target_embed_id').length > 0) {
        return this.getErrors('target_embed_id').join(', ')
      }

      if(this.getErrors('global').length > 0) {
        return this.getErrors('global').join(', ')
      }

      return this.$t('user-portal.user_login_error')
    },

    hasErrors() {
      return !this.$basil.isNil(this.errors) && !this.$basil.isEmpty(this.errors)
    },

    i18n() {
      return this.bootstrap.i18n
    },

    loading() {
      return this.user == null
    },

    userLang() {
      return this.$basil.get(this.$user, 'user.lang', this.i18n.locale.lang.toString())
    },
  },

  methods: {
    onSubmit(e) {
      e.preventDefault()
      return
    }
  },

  mounted() {
    this.user.password = null

    let route = null
    if(this.intent) {
      route = this.$router.resolve(this.intent)
      if(route) {
        route = route.href
      } else {
        route = null
      }
    }

    initGoogleLogin(
      this.$basil.get(this.bootstrap, 'data.google.gsi.client_id', null),
      this.$basil.get(this.$embed, 'embed.id'),
      this.$redirect,
      this.userLang, 
      route
    )
  },

  watch: {
    userLang: {
      handler() {
        let route = null
        if(this.intent) {
          route = this.$router.resolve(this.intent)
          if(route) {
            route = route.href
          } else {
            route = null
          }
        }
        
        initGoogleLogin(
          this.$basil.get(this.bootstrap, 'data.google.gsi.client_id', null),
          this.$basil.get(this.$embed, 'embed.id'),
          this.$redirect,
          this.userLang,
          route
        )
      }
    },
    immediate: false,
  }
}
</script>
