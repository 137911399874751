<template>
  <form
    :class="classes"
    :key="key"
    @submit="onSubmit">

    <ui-banner
      :title="$t('user-portal.user_lost_password_error')"
      intent="danger"
      v-if="errors && errors.status === 401"
    />

    <!-- Email -->
    <forms-input
      autofocus
      autocomplete
      :errors="getErrors('email')"
      name="email"
      :placeholder="$t('user-portal.user_email_placeholder')"
      ref="email"
      required
      @input="onRemoveError('email')"
      v-if="!value.isTokenShown"
      v-model="value.email"
    >{{ $t('user-portal.user_email') }}</forms-input>

    <!-- Token -->
    <forms-input
      :errors="getErrors('token')"
      :hint="$t('user-portal.user_lost_password_token_hint')"
      name="token"
      :placeholder="$t('user-portal.user_lost_password_token_placeholder')"
      ref="token"
      required
      @input="onRemoveError('token')"
      v-if="value.isTokenShown"
      v-model="value.token"
    >{{ $t('user-portal.user_lost_password_token') }}</forms-input>

    <!-- Password -->
    <forms-input
      :errors="getErrors('password')"
      @iconClick="onChangePasswordVisible"
      :icon-post="passwordFieldIcon"
      :icon-post-interactive="true"
      name="password"
      :placeholder="$t('user-portal.user_password_placeholder')"
      ref="password"
      required
      :type="passwordFieldType"
      @input="onRemoveError('password')"
      v-if="value.isTokenShown"
      v-model="value.password"
    >{{ $t('user-portal.user_password') }}</forms-input>

    <!-- Password confirmation -->
    <forms-input
      :errors="getErrors('password_confirmation')"
      @iconClick="onChangePasswordConfirmationVisible"
      :icon-post="passwordConfirmationFieldIcon"
      :icon-post-interactive="true"
      name="password-confirmation"
      :placeholder="$t('user-portal.user_password_confirmation_placeholder')"
      ref="password-confirmation"
      required
      :type="passwordConfirmationFieldType"
      @input="onRemoveError('password_confirmation')"
      v-if="value.isTokenShown"
      v-model="value.passwordConfirm"
    >{{ $t('user-portal.user_password_confirmation') }}</forms-input>

    <!-- Forgot password -->
    <actions-button
      appearance="subtle"
      class="user__link"
      @click="onChangeTokenShown"
    >{{ tokenLabel }}</actions-button>
  </form>
</template>

<script>
import UiBanner from '@/components/ui/banner'
import MixinError from '@/helpers/errors'

export default {
  name: 'UserLostPassword',

  inject: ['$user'],

  mixins: [ MixinError ],

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    errors: {
      type: Object | Array,
      default: {}
    },

    hasBack: {
      type: Boolean,
      default: true,
    },

    value: {
      type: Object,
      required: true,
    }
  },

  components: {
    UiBanner,
  },

  data() {
    return {
      success: false,
      key: 1,
      passwordFieldType: 'password',
      passwordConfirmationFieldType: 'password',
    }
  },

  computed: {
    classes() {
      return {
        'user__form': true,
        'flow': true,
      }
    },

    hasErrors() {
      return this.key && Object.keys(this.errors).length > 0
    },

    passwordConfirmationFieldIcon(){
      return this.passwordConfirmationFieldType === 'password' ? 'visible' : 'hidden'
    },

    passwordFieldIcon() {
      return this.passwordFieldType === 'password' ? 'visible' : 'hidden'
    },

    tokenLabel() {
      return this.key && !this.value.isTokenShown ?
        this.$t('user-portal.user_lost_password_has_token') :
        this.$t('user-portal.user_lost_password_not_has_token')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    onBack() {
      this.$emit('back')
    },

    onChangePasswordVisible() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },

    onChangePasswordConfirmationVisible() {
      this.passwordConfirmationFieldType = this.passwordConfirmationFieldType === 'password' ? 'text' : 'password'
    },

    onChangeTokenShown() {
      this.value.isTokenShown = !this.value.isTokenShown;
      this.key++
    },

    onSubmit(e) {
      e.preventDefault();
      return
    },
  },
}
</script>
