export default {
  inject: [
    '$nft'
  ],

  computed: {
    asset() {
      if(!this.nft) {
        return null
      }

      if(this.isSelfClaim || this.isStatusPending) {
        return this.nft.claimableImage || this.nft.optimizedImageUrl || this.nft.assetUrl || null
      }

      return this.nft.optimizedImageUrl || this.nft.assetUrl || null
    },

    attributes() {
      return this.isSelfClaim || this.isStatusPending ? 
        this.$basil.get(this.nft, 'claimableAttributes', []) : 
        this.$basil.get(this.nft, 'attributes', [])
    },

    confirmRoute() {
      if(this.isSelfClaim) {
        return { name: 'sayl-user-portal.self-claim.nft.confirm' }
      }

      if(this.isPeerTransfer) {
        return { name: 'sayl-user-portal.peer-transfer.nft.confirm' }
      }

      return { name: 'sayl-user-portal.claim.nft.confirm' }
    },

    counter() {
      let current = this.$basil.get(this.$nft, 'claimMeta.selfClaim', 0)
      let max = this.$basil.get(this.nft, 'claimableSupply', 0)

      return this.$t('user-portal.claimable_supply_counter', { current: max - current, max })
    },

    isSelfClaim() {
      return this.$route.meta.selfClaim === true
    },

    isPeerTransfer() {
      return this.$route.meta.peerTransfer === true
    },

    isStatusPending() {
      return this.$basil.get(this.$nft, 'transfer.status') === 10
    },

    loginRoute() {
      if(this.isSelfClaim) {
        return { name: 'sayl-user-portal.self-claim.nft.login' }
      }

      if(this.isPeerTransfer) {
        return { name: 'sayl-user-portal.peer-transfer.nft.login' }
      }

      return { name: 'sayl-user-portal.claim.nft.login' }
    },

    nft() {
      return this.$nft.nft
    },

    signupRoute() {
      if(this.isSelfClaim) {
        return { name: 'sayl-user-portal.self-claim.nft.signup' }
      }

      if(this.isPeerTransfer) {
        return { name: 'sayl-user-portal.peer-transfer.nft.signup' }
      }

      return { name: 'sayl-user-portal.claim.nft.signup' }
    },
  },

  methods: {
    async view(payload) {
      if(this.isSelfClaim) {
        return await this.$nft.viewSelfClaim(payload)
      }

      if(this.isPeerTransfer) {
        return await this.$nft.viewPeerTransfer(payload)
      }

      return await this.$nft.view(payload)
    },

    async claim(payload) {
      if(this.isSelfClaim) {
        return await this.$nft.selfClaim(payload)
      }

      if(this.isPeerTransfer) {
        return await this.$nft.claimPeerTransfer(payload)
      }

      return await this.$nft.claim(payload)
    }
  }
}